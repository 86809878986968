import React, { useState } from 'react'
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Helmet } from "react-helmet"

import "./Introduction.css";

function Introduction(props) {
    const data = props.data;
    return (
        // <div>
        //     <BackgroundImage
        //         Tag="section"
        //         className="background-intro-container"
        //         fluid={backgroundFluidImageStack}
        //         backgroundColor={`#040e18`}
        //     >
        //         <div>
        //             <h1>{data.introductionHeader}</h1>
        //             <p>{data.introductionParagraph}</p>
        //         </div>
        //     </BackgroundImage>
        // </div>

        <div>
            {/* <Helmet>
                <link rel="preload" as="image" href={data.introductionBackgroundImageMobile.imageFile.childImageSharp.fluid.srcSetWebp} imagesrcset={`${data.introductionBackgroundImageMobile.imageFile.childImageSharp.fluid.srcSetWebp}`} />
            </Helmet> */}
            <div className="background-home-container flex-wrap relative">
                <div className="home-img-wrapper hidden sm:block" style={{ padding: 0 }}>
                    <picture>
                        {/* <source type="image/webp" srcSet={data.mobBackImg.imageFile.childImageSharp.fluid.srcSetWebp +"," + data.backImg.imageFile.childImageSharp.fluid.srcSetWebp} className="home-intro-bg" /> */}
                        <source type="image/webp" srcset={data.introductionBackgroundImage.imageFile.childImageSharp.fluid.srcSetWebp} media="(min-width: 601px)"/>
                        {/* <source type="image/webp" srcset={data.introductionBackgroundImageMobile.imageFile.childImageSharp.fluid.srcSetWebp} media="(max-width: 600px)" importance="high" /> */}
                        <source type="image/jpg" srcset={data.introductionBackgroundImage.imageFile.childImageSharp.fluid.srcSet} media="(min-width: 601px)" />
                        {/* <source type="image/jpg" srcset={data.introductionBackgroundImageMobile.imageFile.childImageSharp.fluid.srcSet} media="(max-width: 600px)" /> */}
                        <img
                            srcSet={data.introductionBackgroundImage.imageFile.childImageSharp.fluid.srcSetWebp}
                            style={{ objectFit: "cover", width: "100%", height: "100%" }}
                            alt={data.introductionBackgroundImage.altText}
                        />
                    </picture>
                </div>
                <div className="flex flex-wrap justify-center items-center bg-home-wrapper container">
                    <div className="p-4 w-full sm:w-2/3">
                        <div className="px-0 sm:px-12 text-white text-center sm:text-left">
                            <h1 className="home-h1 sm:text-4xl md:text-5xl">{data.introductionHeader}</h1>
                            <p className="home-main-p py-4 text-2xl sm:text-3xl">{data.introductionParagraph}</p>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/3 flex flex-wrap justify-center">
                        {/* <Link to={"/"+ data.introductionButtonUrl + "/"} className="home-intro-bttn">{data.introductionButton}</Link> */}
                        <div className='w-full flex justify-center'>
                            <a className="home-intro-bttn" href={data.introductionButtonUrl}>{data.introductionButton}</a>
                        </div>

                    </div>
                </div>
                <div className="home-bg-overlay"></div>
            </div>
        </div>
    )
}

export default Introduction;

export const query = graphql`
  fragment IntroductionFragment on WPGraphQL_Page_Homepageacf {
    introductionHeader
    introductionParagraph
    introductionButton
    introductionButtonUrl
    introductionBackgroundImage{
        altText
        sourceUrl
        imageFile{
            childImageSharp{
                fluid(maxWidth: 2080, quality:60){
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
    }
  }
`