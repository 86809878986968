import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Introduction from '../components/Home/Introduction'
// import CoreValues from '../components/Home/CoreValues'
import AboutUs from '../components/Home/AboutUs'
import Services from '../components/Home/Services'
// import OurTeam from '../components/Home/OurTeam'
import CaseStudies from '../components/Home/CaseStudies'
import OurClients from '../components/Home/OurClients'
import Testimonials from '../components/Home/Testimonials'
import ContactForm from "../components/Global/ContactForm"
import { useHasBeenVisible } from '../hooks/useVisibility';



function HomeTemplate(props) {
    // console.log(props)
    let data = props.data.wpgraphql;

    const testimonials = useRef();

    const hasScrolledTestimonial = useHasBeenVisible(testimonials);




    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://enimeris.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://enimeris.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
        >
            <div>
                <section>
                    <Introduction data={data.page.homePageAcf} />
                </section>
                {/* <section>
                    <CoreValues data={data.page.homePageAcf.coreValue} />
                </section> */}
                <section style={{ backgroundColor: "#F8F9FA" }}>
                    <AboutUs data={data.page.homePageAcf.whoWeAre} lang={ props.pageContext.language } />
                </section>
                <section>
                    <Services data={{ title: data.page.homePageAcf.servicesTitle, services: data.serviceCategories.edges }} />
                </section>
                {/* <section>
                    <CaseStudies data={{ title: data.page.homePageAcf.caseStudiesTitle, cases: data.caseStudies.edges, subtitle: data.page.homePageAcf.caseStudiesSubTitle, content: data.page.homePageAcf.caseStudiesPar }} />
                </section> */}
                {/* <section>
                    <OurTeam data={{ title: data.page.homePageAcf.ourTeamHeader, subtitle: data.page.homePageAcf.ourTeamSubHeader, par: data.page.homePageAcf.ourTeamParagraph, members: data.teamMembers.edges }} />
                </section> */}

                <section>
                    <OurClients data={{ title: data.page.homePageAcf.ourClientsTitle, clients: data.clients.edges }} />
                </section>

                {/* {hasScrolledTestimonial ? (
                    <section>
                        <Testimonials data={{ title: data.page.homePageAcf.testimonialsTitle, bgImage: data.page.homePageAcf.testimonialsBackgroundImage, data: data.page.homePageAcf.testimonialsPanel }} />
                    </section>
                ) :
                    (
                        <div ref={testimonials}></div>
                    )} */}

                <section>
                    <ContactForm
                        form={data.template.contactInformationTemplate.form}
                        responses={data.template.contactInformationTemplate.responseMessages}
                        endPoint={"contactEmail"}
                        language={props.pageContext.language}
                        subjectRequired={true}
                        messageRequired={true}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default HomeTemplate

export const pageQuery = graphql`
    query GET_PAGE($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
			    homePageAcf{
                    ...IntroductionFragment
                    coreValue{
                        ...CoreValueFragment
                    }
                    ...AboutUsFragment
                    servicesTitle
                    ourTeamHeader
                    ourTeamSubHeader
                    ourTeamParagraph
                    caseStudiesTitle
                    caseStudiesSubTitle
                    caseStudiesPar
                    ourClientsTitle
                    ...TestimonialFragment
                }
            }
            serviceCategories(where: {language: $language}) {
                ...ServicesFragment
            }
            teamMembers(first: 200, where: {language: $language, orderby: {field: MENU_ORDER, order: ASC}}) {
                ...OurTeamFragment
            }
            caseStudies(where: {language: $language}) {
                ...CaseStudiesFragment
            }
            clients(where: {language: $language}) {
                ...ClientsFragment
            }
            template(id: $themeTemplateUri, idType: SLUG) {
                contactInformationTemplate {
                    form {
                        title
                        subTitle
                        fullName
                        email{
                            label
                            errorMessage
                        }
                        subject
                        message{
                            label
                            errorMessage
                        }
                        telephone{
                            label
                            errorMessage
                        }
                        sendButton
                    }
                    responseMessages{
                        success
                        failed
                    }
                }
            }
        }
    }
`
