import React, { useRef } from 'react'
import { graphql, Link } from 'gatsby'
import Slider from "react-slick";
import Img from "gatsby-image"
import { useHasBeenVisible } from '../../hooks/useVisibility';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./OurClients.css";


const settings = {

    responsive: [
        {
            breakpoint: 2800,
            settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 5,
                autoplay: true,
                autoplaySpeed: 2000,
                pauseOnHover: true,
                cssEase: "linear"
            }
        },
        {
            breakpoint: 768,
            settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                arrows: false,
                slidesToScroll: 2,
                autoplay: true,
                autoplaySpeed: 2000,
                pauseOnHover: true,
                cssEase: "linear"
            }
        },
    ]
};

function OurClients(props) {
    const data = props.data;
    const clients = useRef();


    const hasScrolledClients = useHasBeenVisible(clients);

    return (
        <div className="py-16 oc-container overflow-hidden">
            <div className="text-white m-auto max-w-6xl text-center">
                <h2 className="text-4xl">
                    {data.title}
                </h2>

                {hasScrolledClients ? (
                    <div className="py-8">
                        <Slider {...settings}>
                            {data.clients.map((client, index) => (
                                <div key={`client-${index}`} title={client.node.clientsAcf.clientName}>
                                    <Img fixed={client.node.clientsAcf.clientIcon.imageFile.childImageSharp.fixed} alt={client.node.clientsAcf.clientIcon.altText} className="build-fix-icon" />
                                </div>
                            ))}
                        </Slider>
                    </div>
                ) :
                    (
                        <div ref={clients}></div>
                    )}

            </div>
        </div>
    )
}

export default OurClients;

export const query = graphql`
    fragment ClientsFragment on WPGraphQL_RootQueryToClientConnection {
        edges {
            node {
                clientsAcf {
                    clientName
                    clientIcon {
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fixed(width: 89, quality:100){
                                    base64
                                    width
                                    height
                                    src
                                    srcSet
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

