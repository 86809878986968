import React from 'react'
import { graphql, Link } from 'gatsby'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img from "gatsby-image"
import "./CaseStudies.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                background: "white",
                color: "gray",
                padding: "2rem",
                right: "40px"
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                background: "white",
                color: "gray",
                padding: "2rem",
                left: "40px",
                zIndex: 2,
            }}
            onClick={onClick}
        />
    );
}

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 970,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
    ]
};



function CaseStudies(props) {
    const data = props.data;

    return (
        <div className="py-16 md:py-32">
            <div className="m-auto max-w-6xl text-center" >
                <div className="w-full main-color text-4xl">
                    <h2 className="font-semibold">
                        {data.title}
                    </h2>
                    <h2 >
                        {data.subtitle}
                    </h2>
                </div>
                <p className="text-xl py-4 w-full" style={{color: "#6A6B75"}}>
                    {data.content}
                </p>

                <div className="py-4">
                    <Slider {...settings}>
                        {data.cases.map((cs, index) => (
                            <Link to={`${cs.node.uri}`} className="p-4 cs-link-container" key={`case-stury-${index}`}>
                                <div
                                    className="case-bg-container w-full relative"
                                    fluid={cs.node.caseStudyAcf.featuredImage.imageFile.childImageSharp.fluid}
                                    backgroundColor={`#040e18`}
                                >
                                    <div className="case-title-container py-4 px-8 z-10">
                                        <h3 className="text-2xl text-left">
                                            {cs.node.title}
                                        </h3>
                                        <p className="text-left">
                                            Περισσότερα
                                        </p>
                                    </div>
                                    <div>
                                        <Img fluid={cs.node.caseStudyAcf.featuredImage.imageFile.childImageSharp.fluid} className="h-full w-full m-auto" style={{ maxHeight: "400px" }} />
                                    </div>
                                </div>

                            </Link>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default CaseStudies;

export const query = graphql`
  fragment CaseStudiesFragment on WPGraphQL_RootQueryToCaseStudyConnection {
    edges {
        node {
            title
            uri
            caseStudyAcf {
                featuredImage {
                    sourceUrl
                    imageFile{
                        childImageSharp{
                            fluid{
                                base64
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                            }
                        }
                    }
                }
            }
        }
    }
  }
`
