import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import "./Introduction.css";


function AboutUs(props) {
    const data = props.data;

    return (
        <div className="py-16 md:py-32 m-auto container flex flex-wrap text-center px-4 md:px-0">
            <div className="w-full md:w-1/2 flex flex-wrap text-center md:text-left items-center">
                <div className="w-full main-color text-4xl">
                    <h2 className="font-semibold">
                        {data.title}
                    </h2>
                    <h2 className="pt-4">
                        {data.subtitle}
                    </h2>
                </div>

                <p className="text-xl py-4 w-full" style={{color: "#6A6B75"}}>
                    {data.content}
                </p>
                <div className="w-full md:w-1/2 block md:hidden mt-4">
                    <div className="p-4 block m-auto" style={{maxWidth:"600px"}}>
                        <Img fluid={data.image.imageFile.childImageSharp.fluid} loading="lazy" alt={data.image.altText} className="h-full w-full m-auto" style={{ maxHeight: "400px"}} />
                    </div>  
                </div>
                <div className="w-full">
                    <Link to={props.lang.toLowerCase() === "el" ? "/services" : `/${props.lang.toLowerCase()}/services`} className="main-button-blue mt-4 md:mt-0">
                        {data.button}
                    </Link>
                </div>
            </div>
            <div className="w-1/2 hidden md:block">
                <div className="p-4 block m-auto" style={{maxWidth:"600px"}}>
                    <Img fluid={data.image.imageFile.childImageSharp.fluid} alt={data.image.altText} className="h-full w-full m-auto" style={{ maxHeight: "400px"}} />
                </div>  
            </div>
        </div>
    )
}

export default AboutUs;

export const query = graphql`
  fragment AboutUsFragment on WPGraphQL_Page_Homepageacf {
    whoWeAre {
        title
        content
        subtitle
        button
        image {
            sourceUrl
            altText
            imageFile{
                childImageSharp{
                    fluid(maxWidth: 500, maxHeight:500, quality:80){
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
    }
  }
`