import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Slider from "react-slick";
import Img from "gatsby-image"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Testimonials.css";


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // autoplay: true,
    // autoplaySpeed: 4000,
    pauseOnHover: true,
};

function Testimonials(props) {
    const data = props.data;
    console.log(data)
    return (
        <div className="w-full">
            <div
                className="background-testimonial-container flex-wrap relative"
                backgroundColor={`#040e18`}
            >
                <div className="py-16 w-full overflow-hidden z-10" >
                    <div className="text-white m-auto max-w-6xl text-center px-4">
                        <h2 className="text-4xl">
                            {data.title}
                        </h2>
                        <div className="py-8">
                            <Slider {...settings}>
                                {data.data.map((testimonial, index) => (
                                    <div key={`testimonial-${index}`} >
                                        <p className="text-xl mb-4">
                                            {testimonial.companyName}
                                        </p>
                                        <p className="text-xl">
                                            {testimonial.quote}
                                        </p>
                                        <div className="autowrapper-3"></div>
                                        <Img fixed={testimonial.representativeImage.imageFile.childImageSharp.fixed} loading="eager" alt={testimonial.representativeImage.altText} className="representative-img" />
                                        <div className="font-bold uppercase">
                                            {testimonial.representativeName}
                                        </div>
                                        <div className="uppercase ">
                                            {testimonial.representativePosition}
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full absolute top-0">
                    <Img fluid={data.bgImage.imageFile.childImageSharp.fluid} className="h-full w-full m-auto" style={{ objectFit: "cover" }} />
                </div>
            </div>
        </div>
    )
}

export default Testimonials;

export const query = graphql`
  fragment TestimonialFragment on WPGraphQL_Page_Homepageacf {
    testimonialsTitle
    testimonialsBackgroundImage{
        sourceUrl
        imageFile{
            childImageSharp{
                fluid(maxWidth: 2080, quality:100){
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
    }
    testimonialsPanel{
        companyName
        quote
        representativeImage{
            sourceUrl
            imageFile{
                childImageSharp{
                    fixed(width: 71, quality:100){
                        base64
                        width
                        height
                        src
                        srcSet
                    }
                }
            }
        }
        representativeName
        representativePosition
    }
  }
`


