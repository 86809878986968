import React, { useState } from 'react'
import Img from "gatsby-image"
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Services.css";


function Services(props) {
    const data = props.data;
    return (
        <div className="pt-16 md:pt-32 m-auto text-center" style={props.stylez ? props.stylez : {display:"block" }}>
            {data.title && (
                <h2 className="text-4xl main-color font-semibold" >
                    {data.title}
                </h2>
            )}
            {props.data.services.map((services, index) => (
                <div key={`services-${services.node.name}`} style={index === 1 ? { backgroundColor: "#f8f9fa" } : { backgroundColor: "#ffffff" }} className="py-8 md:py-16" >
                    {data.title ?
                        <h3 className="text-2xl py-4">
                            {services.node.name}
                        </h3>
                        :
                        <h2 className="text-2xl py-4">
                            {services.node.name}
                        </h2>
                    }
                    <div className="container m-auto">
                        <div className="flex flex-wrap py-4">
                            {services.node.services.edges.map((service, yndex) => (
                                <div key={`service-${service.node.title}`} className="w-full md:w-1/3 flex">
                                    <Link to={`${service.node.uri}`} className=" bg-white p-8 m-1 service-tab" >
                                        <Img fixed={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.fixed} alt={service.node.servicesAcf.smallIcon.altText} className="small-icon m-auto" />
                                        {data.title ?
                                            <h4 className="text-2xl py-2 services-h4">
                                                {htmlDecode(service.node.title)}
                                            </h4>
                                            :
                                            <h3 className="text-2xl py-4">
                                                {htmlDecode(service.node.title)}
                                            </h3>
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="service-excerpt-container" />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Services;

export const query = graphql`

  fragment ServicesFragment on WPGraphQL_RootQueryToServiceCategoryConnection {
    edges {
        node {
            name
            services (first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}){
                edges{
                    node{
                        uri
                        title
                        excerpt
                        servicesAcf{
                            smallIcon{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp{
                                        fixed(width: 40){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  }
`